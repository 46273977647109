
/* ----------------------------- 구독 ----------------------------- */
/* ----------------------------------------------------------------- */
/* ----------------------------- 구독 - 목록 ----------------------------- */
/* 구독 > 목록 - 새로운 콘텐츠 */
.lytScrNewContents {width:100%; margin:0; margin-bottom: 125px;}
.lytUylListTopbtn + .lytScrNewContents {margin-top:-34px;}
.lytScrNewContents .contentsArea {width:100%; max-width:1385px; margin:0 auto;}
.lytScrNewContents .contentsTitGroup {margin-bottom:30px; position:relative;}
.lytScrNewContents .contentsTitGroup .title {height:40px;}
.lytScrNewContents .contentsTitGroup .title > h3 {height: 40px; font-size: 24px; font-weight: bold; line-height: 1.67; letter-spacing: -0.5px; color: #000000;}
.lytScrNewContents .contentsTitGroup .pagenation {width:80px; height:40px; position:absolute; right:0; top:0;}
.lytScrNewContents .contentsTitGroup .pagenation:after {content:""; display:block; clear:both;}
.lytScrNewContents .contentsTitGroup .pagenation .btnPrev {width:40px; height:40px; font-size:0; background:url(/images/icon/btn-l-arrow-left.svg) center no-repeat; float:left;}
.lytScrNewContents .contentsTitGroup .pagenation .btnNext {width:40px; height:40px; font-size:0; background:url(/images/icon/btn-l-arrow-right.svg) center no-repeat; float:left;}
.lytScrNewContents .contentsContGroup {}
.lytScrNewContents .contentsContGroup .swiper-container {}
.lytScrNewContents .swiper-container {}
.lytScrNewContents .swiper-container .swiper-wrapper {}
.lytScrNewContents .swiper-container .swiper-slide {min-width:212px;}
.lytScrNewContents .swiper-container .swiper-slide:last-child {margin-right:0;}

@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytScrNewContents {margin:50px 0;}
    .lytScrNewContents .contentsArea {width:100%; max-width:none; margin:0 auto;}
    .lytScrNewContents .contentsTitGroup {margin-bottom:16px; padding:0 20px;}
    .lytScrNewContents .contentsTitGroup .title {height:32px;}
    .lytScrNewContents .contentsTitGroup .title > h3 {height: 32px; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px;}
    .lytScrNewContents .contentsTitGroup .pagenation {display:none;}
    .lytScrNewContents .swiper-container {padding-left:20px;}
    .lytScrNewContents .swiper-container .swiper-slide {width:212px !important;}
}

/* 구독 > 목록 - 전체 콘텐츠 */
.lytScrAllContents {width:100%; margin-top:80px;}
.lytScrAllContents .listArea {width:100%; max-width:1384px; margin:0 auto;}
.lytScrAllContents .listTitGroup {margin-bottom:30px; position:relative;}
.lytScrAllContents .listTitGroup .title {height:40px;}
.lytScrAllContents .listTitGroup .title > h3 {height: 40px; font-size: 24px; font-weight: bold; line-height: 1.67; letter-spacing: -0.5px; color: #000000;}
.lytScrAllContents .listSearchGroup {margin-bottom:48px;}
.lytScrAllContents .listArea .listSortGroup:after {content:""; display:block; clear:both;}
.lytScrAllContents .listArea .listSortGroup .checkArea {width:400px; padding-top: 12px; float:left;}
.lytScrAllContents .listArea .listSortGroup .selectArea {width:160px; float:right;}
.lytScrAllContents .listArea .listContGroup {margin-top:72px;}
.lytScrAllContents .listArea .thumBoard {width:100%;}
.lytScrAllContents .listArea .thumBoard:after {content:""; display:block; clear:both;}
.lytScrAllContents .listArea .thumBoard > .item {width:23.121%; margin-right:2.5%; margin:0 2.5% 80px 0; float:left;}
.lytScrAllContents .listArea .thumBoard > .item:nth-child(4n+4) {margin-right:0;}
.lytScrAllContents .listArea .thumBoard > .item img {width:100%;}

@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytScrAllContents {width:100%; margin-top:35px;}
    .lytScrAllContents .listArea {max-width:none; padding:0 20px;}
    .lytScrAllContents .listTitGroup {margin-bottom:16px; padding:0;}
    .lytScrAllContents .listTitGroup .title {height:32px;}
    .lytScrAllContents .listTitGroup .title > h3 {height: 32px; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px;}
    .lytScrAllContents .listSearchGroup {margin-bottom:10px;}
    .lytScrAllContents .listArea .listSortGroup:after {content:""; display:block; clear:both;}
    .lytScrAllContents .listArea .listSortGroup .checkArea {width:225px; padding-top: 6px; float:left;}
    .lytScrAllContents .listArea .listSortGroup .selectArea {width:100px; float:right;}
    .lytScrAllContents .listArea .listContGroup {margin-top:26px;}
    .lytScrAllContents .listArea .thumBoard > .item {width:100%; margin:0 2.5% 34px 0;}
}

/* 콘텐츠 내 검색박스 */
.comSearchInCont {width:100%; height:78px; padding-top:19px; font-size:0; text-align:center; background:#f2f2f2;}
.comSearchInCont .searchArea {width:990px; display:inline-block;}
.formSearchBox {width:100%; height:40px; position:relative;}
.formSearchBox input {width:100%; height:40px; padding-left:23px; font-size: 18px; font-weight: normal; line-height: 1.78; letter-spacing: -0.38px; color: #7c7c7c; border:0;}
.formSearchBox .btnSearch{width: 24px; height: 24px; font-size: 0; background: url(/images/icon/ico-24-search.svg) center no-repeat; position: absolute; right: 17px; top: 7px;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .comSearchInCont {width:100%; height:48px; padding:12px 16px;}
    .comSearchInCont .searchArea {width:100%;}
    .formSearchBox input {width:100%; height:24px; padding-left:0; font-size: 14px; line-height: 1.71; letter-spacing: -0.29px; background:#f2f2f2;}
    .formSearchBox .btnSearch{width: 24px; height: 24px; font-size: 0; background: url(/images/icon/ico-24-search.svg) center no-repeat; opacity:0.6; position: absolute; right: 0; top: 0;}
}

/* 체크박스 구독 - 내가 좋아요한 콘텐츠만 보기 */
.formSortCheck {}
.formSortCheck + .labelSortCheck {height:32px; padding-left:26px; font-size: 16px; line-height: normal; letter-spacing: normal; color: #414141; position:relative;}
.formSortCheck + .labelSortCheck:after {content:""; width:16px; height:16px; background:url(/images/icon/input-checkbox-default.svg) center no-repeat; position:absolute; left:0; top:4px;}
.formSortCheck:checked + .labelSortCheck:after {background:url(/images/icon/input-checkbox-abled.svg) center no-repeat;}
.formSortCheck:focus + .labelSortCheck {box-shadow: 0px 0px 0px 2px rgba(0,0,0,1); border-radius: 5px;}
@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .formSortCheck + .labelSortCheck {height:20px; padding-left:27px; font-size: 14px; line-height: 20px; letter-spacing: -0.29px;}
    .formSortCheck + .labelSortCheck:after {content:""; width:20px; height:20px; background:url(/images/icon/input-checkbox-default.svg) center no-repeat; background-size:20px; top:0px;}
    .formSortCheck:checked + .labelSortCheck:after {background:url(/images/icon/input-checkbox-abled.svg) center no-repeat; background-size:20px;}
}

/* select 박스 list ver */
.formSortSelect {width: 100%; height: 42px; border: none; border-bottom: 2px solid #000; font-size: 18px; color: #7c7c7c;  background: url(/images/icon/ico-24-arrow-down.svg) right center no-repeat;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .formSortSelect{height:32px; border:0; font-size:14px; direction: rtl; padding-right:27px;}
    .formSortSelect option {direction: rtl;}
}
/* ----------------------------- 구독 홈 - 팝업 ----------------------------- */
/* 구독 > 플레이리스트 > 팝업 */
.lytMysubscript {width: 100%;}
.lytMysubscript .mineArea {width: 100%;}
.lytMysubscript .photolist .list {width: 100%; margin-bottom: 22px;}
.lytMysubscript .photolist .list:last-child {margin-bottom: 0;}
.lytMysubscript .photolist .list::after {display: block; content: ""; clear: both;}

.lytMysubscript .comLoadingBar {margin-top: 40px;}

/* 구독 > 내 플레이리스트에 담기 팝업 */
.lytNewplaylist	{width: 100%;}
.lytNewplaylist	.addArea {width: 100%;}
.lytNewplaylist	.newList {width: 100%; margin-bottom: 24px;}
.lytNewplaylist	.newList h4 {margin-bottom: 13px;}
.lytNewplaylist	.newList h4::after {display: block; content: ""; clear: both;}
.lytNewplaylist	.newList h4 .tit {font-size: 16px; color: #000; font-weight: 400; float: left;}
.lytNewplaylist	.newList h4 .radioGroup {width: auto; font-size: 0; float: right;}
.lytNewplaylist	.newList h4 .radioGroup .radioItem {margin: 0 0 0 15px;display:inline-block;}
.lytNewplaylist	.newList h4 .radioGroup .formRadio + .labelRadio {font-size: 16px; color: #4a4a4a; font-weight: 400;}
.lytNewplaylist	.newList .inputPart {width: 100%;}
.lytNewplaylist	.newList .inputPart input {width: calc(100% - 97px); height: 52px; margin-right: 10px; padding-left: 20px; border: 1px solid #e5e5e5; font-size: 16px; color:#4a4a4a;}
.lytNewplaylist	.newList .inputPart input::placeholder {color: #b2b2b2;}
.lytNewplaylist	.newList .inputPart input::-webkit-input-placeholder {color: #b2b2b2;}
.lytNewplaylist	.newList .inputPart input:-ms-input-placeholder {color: #b2b2b2;}
.lytNewplaylist	.newList .inputPart .btn.sbmit {width: 85px; height: 52px; font-size: 16px; font-weight: 500; color: #fff; background: #3c89f9;}
.lytNewplaylist	.existingList {width: 100%; height: 253px; margin-bottom: 42px; border: 1px solid #e5e5e5; padding: 32px 20px; overflow-y: auto;}
.lytNewplaylist	.existingList .list {width: 100%; margin-bottom: 22px;line-height: 42px; display: inline-block; vertical-align: middle;}
.lytNewplaylist	.existingList .list:last-child {margin-bottom: 0;}
.lytNewplaylist	.existingList .list .listName {width: calc(100% - 95px); padding-right: 10px; line-height: 1.3; font-size: 18px; color: #000; display: inline-block; vertical-align: middle;}
.lytNewplaylist	.existingList .list .btn.select {width: 85px; height: 42px; background: #7f7f7f; font-size: 16px; font-weight: 500; color: #fff; display: inline-block; vertical-align: middle;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytNewplaylist	.newList {margin-bottom: 30px;}
    .lytNewplaylist	.newList h4 .tit {line-height: 16px;}
    .lytNewplaylist	.newList h4 .radioGroup .radioItem {margin: 0 0 0 20px; line-height: 16px;}
    .lytNewplaylist	.newList h4 .radioGroup .formRadio + .labelRadio {font-size: 14px;line-height: 16px;}
    .lytNewplaylist	.newList h4 .radioGroup .formRadio + .labelRadio::after {width: 16px; height: 16px; background-size: 16px;}
    .lytNewplaylist	.newList .inputPart .btn.sbmit {font-size: 14px;}
    .lytNewplaylist	.existingList {height: 400px; margin-bottom: 46px; padding: 37px 20px;}
    .lytNewplaylist	.existingList .list {margin-bottom: 32px;line-height: 34px; }
    .lytNewplaylist	.existingList .list .listName {width: calc(100% - 80px); font-size: 16px;}
    .lytNewplaylist	.existingList .list .btn.select {width: 70px; height: 34px;font-size: 14px;}
}

/* ----------------------------- 구독 - 채널:항목없음 ----------------------------- */
.lytNoneContents {width: 100%; margin-top: 80px;}
.lytNoneContents .noneArea {width: 100%; max-width: 1385px; margin: 0 auto;}
.lytNoneContents .noneAlert {width: 320px; margin: 0 auto; padding: 48px 50px; background: #f2f2f2;}
.lytNoneContents .noneAlert .imgArea {width: 160px; height: 160px; margin: 0 auto 24px auto; border-radius: 50%; overflow: hidden;}
.lytNoneContents .noneAlert .imgArea img {width: 100%;}
.lytNoneContents .noneAlert .nonetxt dt {font-size: 18px; font-weight: 700; color: #000; text-align: center;}
.lytNoneContents .noneAlert .nonetxt dd {margin-top: 5px; font-size: 18px; color: #000; text-align: center;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytNoneContents {margin-top: 46px;}
    .lytNoneContents .noneArea {padding: 0 20px;}
    .lytNoneContents .noneAlert {width: 100%; padding: 24px 20px;}
    .lytNoneContents .noneAlert .imgArea {width: 104px; height: 104px; margin: 0 auto 17px auto; border-radius: 50%; overflow: hidden;}
    .lytNoneContents .noneAlert .nonetxt dt {font-size: 14px;}
    .lytNoneContents .noneAlert .nonetxt dd {font-size: 14px;}
}

/* ----------------------------- 구독 - 채널 ----------------------------- */
/* 구독 > 채널 - 채널명 */
.lytChannelSmry {width: 100%; margin-bottom: 50px;background: #fbfbfb;}
.lytChannelSmry .smryArea {padding: 80px 0 50px;}

.lytChannelSmry .profSection {margin-bottom: 20px; text-align: center;}
.lytChannelSmry .profSection .profItem {display: inline-block; position: relative; width: 120px; height: 120px;}
.lytChannelSmry .profSection .profItem .picBox {overflow: hidden; width: 100%; height: 100%; border-radius: 50%;}
.lytChannelSmry .profSection .profItem .picBox img {width: 100%;}
.lytChannelSmry .profSection .profItem .regBtn {position: absolute; right: 0; bottom: 0; width: 31px; height: 31px; background: url(/images/icon/ico-44-camera.svg) center no-repeat; font-size: 0;}

.lytChannelSmry .infoSection {margin-bottom: 20px; text-align: center;}
.lytChannelSmry .infoSection .channelInfo {font-size: 0;}
.lytChannelSmry .infoSection .channelInfo .tit {display: inline-block; color: #000000; font-size: 24px; font-weight: bold; line-height: 1.33; letter-spacing: -0.5px; vertical-align: middle;}
.lytChannelSmry .infoSection .channelInfo .revBtn {width: 28px; height: 28px; margin-left: 5px; background: url(/images/icon/ico-24-write.svg) center no-repeat; font-size: 0;}
.lytChannelSmry .infoSection .hostInfo {font-size: 0;}
.lytChannelSmry .infoSection .hostInfo span {display: inline-block; color: #000000; font-size: 18px; line-height: 1.78; letter-spacing: -0.38px;}
.lytChannelSmry .infoSection .hostInfo .name {position: relative; padding-right: 14px;}
.lytChannelSmry .infoSection .hostInfo .name:after {content: ''; position: absolute; right: 0; top: calc(50% - 1.5px); width: 3px; height: 3px; border-radius: 50%; background: #000000;}
.lytChannelSmry .infoSection .hostInfo .sub {padding-left: 9px;}
.lytChannelSmry .infoSection .hostInfo .sub .num {letter-spacing: -0.38px;}

.lytChannelSmry .btnSection {text-align: center;}
.lytChannelSmry .btnSection .uldBtn {width: 164px; height: 38px; background: #7f7f7f; color: #ffffff; font-size: 14px; font-weight: 500; letter-spacing: -0.29px;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytChannelSmry {margin-bottom: 18px;}
    .lytChannelSmry .smryArea {padding: 30px 0;}

    .lytChannelSmry .profSection {margin-bottom: 8px;}
    .lytChannelSmry .profSection .profItem {width: 60px; height: 60px;}

    .lytChannelSmry .infoSection {margin-bottom: 17px;}
    .lytChannelSmry .infoSection .channelInfo .tit {font-size: 18px; line-height: normal; letter-spacing: -0.38px;}
    .lytChannelSmry .infoSection .channelInfo .revBtn {width: 20px; height: 20px; background-size: 20px;}
    .lytChannelSmry .infoSection .hostInfo span {font-size: 12px; line-height: 1.67; letter-spacing: -0.25px;}
    .lytChannelSmry .infoSection .hostInfo .name {padding-right: 10px;}
    .lytChannelSmry .infoSection .hostInfo .name:after {top: 50%; width: 2px; height: 2px;}
    .lytChannelSmry .infoSection .hostInfo .sub {padding-left: 5px;}
    .lytChannelSmry .infoSection .hostInfo .sub .num {letter-spacing: -0.25px;}

    .lytChannelSmry .btnSection .uldBtn {height: 32px; font-size: 12px; letter-spacing: -0.25px;}
}

/* 구독 > 채널 - 채널설정 */
/* (토글 영역) */
.modLytToggle {}
.modLytToggle .toggleArea {position: relative; width: 100%; max-width: 1384px; margin: 0 auto;}
.modLytToggle .btnBox {position: absolute; right: 0; top: 7px; z-index: 999;}
.modLytToggle .btnBox .tglBtn {height: 27px; padding-right: 39px; background: url(/images/icon/ico-24-arrow-down.svg) right center / 34px no-repeat; color: #4a4a4a; font-size: 18px; line-height: normal; letter-spacing: -0.75px;}
.modLytToggle .channelDetailGrp {position: relative;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .modLytToggle .btnBox {position: static; padding: 0 20px; text-align: right;}
    .modLytToggle .btnBox .tglBtn {height: 20px; padding-right: 29px; background-size: 24px; font-size: 14px; letter-spacing: -0.58px;}
}

/* (타이틀) */
.lytChannelDetail {width: 100%; margin-bottom: 80px;}
.lytChannelDetail .detailArea {width: 100%; max-width: 1384px; margin: 0 auto;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytChannelDetail {margin-bottom: 46px;}
    .lytChannelDetail .detailArea {max-width: none; padding: 0 20px;}
}

.lytChannelDetail .titSection {margin-bottom: 23px;}
.lytChannelDetail .titSection:after {content: ''; display: block; clear: both;}
.lytChannelDetail .titSection .titBox {float: left; font-size: 0;}
.lytChannelDetail .titSection .titBox .tit {display: inline-block; height: 40px; color: #000000; font-size: 24px; font-weight: bold; line-height: 1.67; letter-spacing: -0.5px; vertical-align: middle;}
.lytChannelDetail .titSection .titBox .revBtn {width: 28px; height: 28px; margin-left: 12px; background: url(/images/icon/ico-24-write.svg) center / 28px no-repeat; font-size: 0;}
.lytChannelDetail .titSection .btnBox {float: right;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytChannelDetail .titSection {margin-bottom: 16px;}
    .lytChannelDetail .titSection .titBox .tit {height: 27px; font-size: 18px; line-height: normal; letter-spacing: normal;}
    .lytChannelDetail .titSection .titBox .revBtn {width: 20px; height: 20px; margin-left: 5px; background-size: 20px;}
}

/* (설명) */
.lytChannelDetail .descSection {border: 1px solid #e5e5e5;}
.lytChannelDetail .descSection textarea {width: 100%; height: 122px; padding: 20px; border: 0; color: #000000; font-size: 16px; line-height: 1.75; letter-spacing: -0.03px;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytChannelDetail .descSection textarea {padding: 12px; font-size: 14px; line-height: 2;}
}

/* (키워드) */
.lytChannelDetail .keywordSection {padding: 20px 20px 10px; border: 1px solid #e5e5e5;}
.lytChannelDetail .keywordSection .keywordList {}
.lytChannelDetail .keywordSection .keywordList:after {content: ''; display: block; clear: both;}
.lytChannelDetail .keywordSection .keywordList .item {float: left; margin-right: 16px; margin-bottom: 10px;}
.lytChannelDetail .keywordSection .keywordList .item:last-child {margin-right: 0;}
.lytChannelDetail .keywordSection .keywordList .item.btn {padding: 7px 0;}
.lytChannelDetail .keywordSection .keywordList .item .keyword {display: block; height: 38px; padding: 11px 28px 0; background: #ebf3ff; color: #3c89f9; font-size: 14px; font-weight: 500; line-height: 1.14; letter-spacing: -0.58px; text-align: center;}
.lytChannelDetail .keywordSection .keywordList .item .keywordBtn {height: 24px; padding-left: 32px; background: url(/images/icon/ico-24-add.svg) 0 center no-repeat; color: #b2b2b2; font-size: 16px; font-weight: 500; line-height: normal; letter-spacing: -0.67px;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytChannelDetail .keywordSection {padding: 12px 12px 2px;}

    .lytChannelDetail .keywordSection .keywordList .item {}
    .lytChannelDetail .keywordSection .keywordList .item .keyword {height: 34px; padding: 9px 31px 0; font-size: 12px; line-height: 1.33; letter-spacing: -0.5px;}
    .lytChannelDetail .keywordSection .keywordList .item .keywordBtn {height: 20px; background-size: 20px; font-size: 14px; letter-spacing: -0.58px;}
}

/* (활동분야) */
.lytChannelDetail .ctgySection {padding: 20px; border: 1px solid #e5e5e5;}
.lytChannelDetail .ctgySection .selCtgy .ctgyUnit {margin-bottom: 22px;}
.lytChannelDetail .ctgySection .selCtgy .ctgyUnit:last-child {margin-bottom: 0;}
.lytChannelDetail .ctgySection .selCtgy .ctgyUnit dl {font-size: 0;}
.lytChannelDetail .ctgySection .selCtgy .ctgyUnit dt {display: inline-block; width: 87px; height: 27px; color: #000000; font-size: 18px; line-height: normal; letter-spacing: -0.38px; vertical-align: middle;}
.lytChannelDetail .ctgySection .selCtgy .ctgyUnit dd {display: inline-block; width: 295px; vertical-align: middle;}

.lytChannelDetail .ctgySection .txtCtgy {display: none;}
.lytChannelDetail .ctgySection .txtCtgy .ctgyUnit {display: inline-block; height: 28px; color: #000000; font-size: 14px; line-height: 2; letter-spacing: -0.03px;}
.lytChannelDetail .ctgySection .txtCtgy .ctgyUnit:after {content: '>'; display: inline-block; width: 21px; text-align: center;}
.lytChannelDetail .ctgySection .txtCtgy .ctgyUnit:last-child:after {content: none;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytChannelDetail .ctgySection {padding: 12px;}
    .lytChannelDetail .ctgySection .selCtgy {display: none;}
    .lytChannelDetail .ctgySection .txtCtgy {display: block;}
}
/* ----------------------------------------------------------------- */

.lytUylListTopbtn {width: 100%; margin-bottom: 50px;}
.lytUylListTopbtn .buttonArea {width: 100%; max-width: 1385px; margin: 0 auto; text-align: right;}
.lytUylListTopbtn .buttonArea .btn {display: inline-block; line-height: 64px;}

.btn {}
.btn.blue {color: #ffffff; background: #3c89f9;}
.btn.grey {color: #7f7f7f; background: #e5e5e5;}
.btn.dark {color: #ffffff; background: #7f7f7f;}
.btn.line {color: #3c89f9; background: #ffffff; border: 1px solid #3c89f9;}
.btn.black {color: #ffffff; background: #000000;}
.btn.lg {height:64px; margin-left:10px; padding:0 87.5px; font-size: 18px; font-weight: 700; line-height: 1.78; letter-spacing: -0.38px; text-align: center;}
.btn.lg:first-child {margin-left:0;}
.btn.alert {width:130px; height:40px; margin-right:10px; font-size: 15px; line-height: 1.33;}
.btn.alert:last-child {margin-right:0;}

@media screen and (min-width: 100px) and (max-width: 1384px) {
    .lytUylListTopbtn .buttonArea {padding: 0 20px;}
}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytUylListTopbtn .buttonArea .btn {line-height: 48px;}
}

/* 구독 > 내가 구독한 채널 리스트 반복아이템 */
.cptMineChannel {width: 100%;}
.cptMineChannel .photoArea {width: 62px; height: 62px; margin-right: 22px; border-radius: 31px; text-align: center; overflow: hidden; float: left;}
.cptMineChannel .photoArea img {width: 100%;}
.cptMineChannel .channelName {width: calc(100% - 84px); margin-top: 15px; float: left; position: relative;}
.cptMineChannel .channelName .name {max-width:calc(100% - 25px); font-size: 18px; font-weight: 700; color: #000; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;display: inline-block;}
.cptMineChannel.newAlert .channelName::after {display: inline-block; content: ""; width: 6px; height: 6px; margin: 4px 0 0 14px; border-radius: 3px; background: #3c89f9; vertical-align: top;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
	.cptMineChannel .photoArea {width: 62px; height: 62px; margin-right: 22px; border-radius: 31px; text-align: center; overflow: hidden; float: left;}
	.cptMineChannel .photoArea img {width: 100%;}
	.cptMineChannel .channelName {width: calc(100% - 84px); margin-top: 15px; float: left; position: relative;}
	.cptMineChannel .channelName .name {max-width:calc(100% - 25px); font-size: 18px; font-weight: 700; color: #000; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;display: inline-block;}
	.cptMineChannel.newAlert .channelName::after {display: inline-block; content: ""; width: 6px; height: 6px; margin: 4px 0 0 14px; border-radius: 3px; background: #3c89f9; vertical-align: top;}
}


/* ------------------------------ 구독 - 채널 ------------------------------ */
/* MY콘텐츠 - 업로드콘텐츠 */
.cptChannelContItem {width: 100%;}

.cptChannelContItem .channelContLink {display: block; width: 100%; height: 100%; margin-bottom: 16px;}
.cptChannelContItem .contThumBox {position: relative; margin-bottom: 16px;}
.cptChannelContItem .contThumBox .state {position: absolute; left: 0; top: 0; height: 32px; padding: 6px 10px 0; background: #000000; color: #ffffff; font-size: 12px; font-style: normal; font-weight: 500; line-height: 1.67; letter-spacing: -0.16px;}
.cptChannelContItem .contThumBox img {width: 100%; vertical-align: top;}

.cptChannelContItem .contInfoBox {}
.cptChannelContItem .contInfoBox .titInfo {margin-bottom: 8px;}
.cptChannelContItem .contInfoBox .titInfo h4 {overflow: hidden; display: -webkit-box; width: 100%; height: 64px; color: #000000; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px; text-overflow: ellipsis; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.cptChannelContItem .contInfoBox .ctgyInfo {margin-bottom: 18px; font-size: 0;}
.cptChannelContItem .contInfoBox .ctgyInfo .ctgyUnit {display: inline-block; color: #7c7c7c; font-size: 14px; line-height: 1.71; letter-spacing: -0.29px;}
.cptChannelContItem .contInfoBox .ctgyInfo .ctgyUnit.mShow {display: none;}
.cptChannelContItem .contInfoBox .ctgyInfo .ctgyUnit:after {content: '∙'; display: inline-block; margin: 0 5px;}
.cptChannelContItem .contInfoBox .ctgyInfo .ctgyUnit:nth-last-child(2):after {content: none;}
.cptChannelContItem .contInfoBox .ctgyInfo .ctgyUnit:last-child:after {content: none;}
.cptChannelContItem .contInfoBox .userInfo {font-size: 0;}
.cptChannelContItem .contInfoBox .userInfo .userUnit {display: inline-block; margin-right: 16px;}
.cptChannelContItem .contInfoBox .userInfo .userUnit:last-child {margin-right: 0;}
.cptChannelContItem .contInfoBox .userInfo .userUnit span {display: inline-block; vertical-align: middle;}
.cptChannelContItem .contInfoBox .userInfo .userUnit .icon {width: 16px; height: 16px; margin-right: 4px;}
.cptChannelContItem .contInfoBox .userInfo .userUnit .like {background: url(/images/icon/ico-16-like.svg) center no-repeat;}
.cptChannelContItem .contInfoBox .userInfo .userUnit .view {background: url(/images/icon/ico-16-play.svg) center no-repeat;}
.cptChannelContItem .contInfoBox .userInfo .userUnit .num {color: #7c7c7c; font-family: Rubik, NotoSansCJKkr; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px;}

.cptChannelContItem .channelContBtn {font-size: 0;}
.cptChannelContItem .channelContBtn button {width: 100%; height: 38px; font-size: 14px; font-weight: 500; line-height: 1.43; letter-spacing: -0.18px;}
.cptChannelContItem .channelContBtn.type02 button {width: calc(50% - 5px); margin-right: 10px;}
.cptChannelContItem .channelContBtn.type02 button:last-child {margin-right: 0;}
.cptChannelContItem .channelContBtn .btnRev {border: 1px solid #b2b2b2; background: #ffffff; color: #b2b2b2;}
.cptChannelContItem .channelContBtn .btnRej {background: #000000; color: #ffffff;}
.cptChannelContItem .channelContBtn .btnReg {background: #7f7f7f; color: #ffffff;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .cptChannelContItem .contThumBox {margin-bottom: 12px;}
    .cptChannelContItem .contThumBox .state {height: 35px; padding: 8px 10px 0;}
    .cptChannelContItem .contInfoBox .titInfo {margin-bottom: 4px;}
    .cptChannelContItem .contInfoBox .titInfo h4 {height: 48px; font-size: 16px; line-height: 1.5; letter-spacing: -0.33px;}
    .cptChannelContItem .contInfoBox .ctgyInfo {margin-bottom: 4px;}
    .cptChannelContItem .contInfoBox .ctgyInfo .ctgyUnit {font-size: 12px; line-height: 1.67; letter-spacing: -0.25px;}
    .cptChannelContItem .contInfoBox .ctgyInfo .ctgyUnit.mShow {display: inline-block;}
    .cptChannelContItem .contInfoBox .ctgyInfo .ctgyUnit:after {content: '>'; margin: 0 3px;}
    .cptChannelContItem .contInfoBox .ctgyInfo .ctgyUnit:nth-last-child(2):after {content: '>';}
}

/* 구독 > 채널 - MY콘텐츠 */
.modChannelContents {width: 100%;}
.modChannelContents .channelContsArea {width: 100%; max-width: 1384px; margin: 0 auto;}

.modChannelContents .channelTit {margin-bottom: 23px;}
.modChannelContents .channelTit:after {content: ''; display: block; clear: both;}
.modChannelContents .channelTit .titBox {float: left;}
.modChannelContents .channelTit .titBox .tit {height: 40px; color: #000000; font-size: 24px; font-weight: bold; line-height: 1.67; letter-spacing: -0.5px;}

.modChannelContents .channelTab {}
.modChannelContents .channelTab .tabList {border-bottom: 2px solid rgba(0,0,0,.1);}
.modChannelContents .channelTab .tabList:after {content: ''; display: block; clear: both;}
.modChannelContents .channelTab .tabList .tab {position: relative; float: left;}
.modChannelContents .channelTab .tabList .tab .tabBtn {padding: 0 40px 14px; color: #000000; font-size: 20px; letter-spacing: 0.56px;}
.modChannelContents .channelTab .tabList .tab.on:after {content: ''; position: absolute; left: 0; bottom: 0; width: 100%; height: 2px; background: #3c89f9;}
.modChannelContents .channelTab .tabList .tab.on .tabBtn {color: #3c89f9; font-weight: bold;}

.modChannelContents .channelTabCont {display: none;}
.modChannelContents .channelTabCont.on {display: block;}

@media screen and (min-width: 100px) and (max-width: 1384px){
    .modChannelContents .channelContsArea {max-width: none;}
    .modChannelContents .channelTit {padding: 0 20px;}
    .modChannelContents .channelTab {padding: 0 20px;}
}
@media screen and (min-width: 100px) and (max-width: 1023px){
    .modChannelContents .channelTit {margin-bottom: 16px;}
    .modChannelContents .channelTit .titBox .tit {height: 27px; font-size: 18px; line-height: normal; letter-spacing: normal;}
    .modChannelContents .channelTab .tabList .tab {width: 50%;}
    .modChannelContents .channelTab .tabList .tab .tabBtn {width: 100%; padding: 0 0 10px; font-size: 14px; line-height: 1.71; letter-spacing: 0.39px;}

}
/* 구독 > 채널 - MY콘텐츠 - 업로드콘텐츠 */
.lytUploadCont {}
.lytUploadCont .uploadContArea {}
.lytUploadCont .uploadContGroup {}
.lytUploadCont .uploadContList {width: 100%;}
.lytUploadCont .uploadContList:after {content: ''; display: block; clear: both;}
.lytUploadCont .uploadContList .uploadItem {float: left; width: calc(25% - 26.25px); margin: 50px 35px 0 0;}
.lytUploadCont .uploadContList .uploadItem:nth-child(4n) {margin-right: 0;}

@media screen and (min-width: 100px) and (max-width: 1384px) {
    .lytUploadCont .uploadContArea {padding: 0 20px;}
}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytUploadCont .uploadContList .uploadItem {float: none; width: 100%; margin: 34px 0 0 0;}
    .lytUploadCont .uploadContList .uploadItem:first-child {margin: 26px 0 0 0;}
}

/* 구독 > 원형 썸네일 반복 아이템 */
.cptRoundthum {position: relative; width: 100%; background: #f2f2f2;}
.cptRoundthum .roundthumLink {display: block; width: 100%; height: 100%;}
.cptRoundthum .roundthumImgGroup {margin-bottom: 24px; padding-top: 48px; text-align: center;}
.cptRoundthum .roundthumImgGroup .imgBox {overflow: hidden; display: inline-block; width: 160px; height: 160px; border-radius: 50%;}
.cptRoundthum .roundthumImgGroup .imgBox img {width: 100%; min-width: 100%; min-height: 100%;}

.cptRoundthum .roundthumContGroup {padding-bottom: 48px;}
.cptRoundthum .roundthumTitle {margin-bottom: 8px; text-align: center;}
.cptRoundthum .roundthumTitle h4 {overflow: hidden; height: 32px; padding: 0 10px; color: #000; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px; white-space: nowrap; text-overflow: ellipsis;}
.cptRoundthum .roundthumHost {margin-bottom: 24px; text-align: center;}
.cptRoundthum .roundthumHost .name {height: 32px; color: #000; font-size: 18px; line-height: 1.78; letter-spacing: -0.38px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding: 0 5px; }
.cptRoundthum .roundthumInfo {text-align: center; font-size: 0;}
.cptRoundthum .roundthumInfo .infoItem {display: inline-block; margin-right: 16px;}
.cptRoundthum .roundthumInfo .infoItem:last-child {margin-right: 0;}
.cptRoundthum .roundthumInfo .infoItem span {display: inline-block; vertical-align: middle;}
.cptRoundthum .roundthumInfo .infoItem .view {position: relative; height: 20px; padding-left: 20px; color: #7c7c7c; font-family: Rubik; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px;}
.cptRoundthum .roundthumInfo .infoItem .view:after {content: ''; position: absolute; left: 0; top: 2px; width: 16px; height: 16px; background: url(/images/icon/ico-16-view.svg) center no-repeat;}
.cptRoundthum .roundthumInfo .infoItem .list {position: relative; height: 20px; padding-left: 20px; color: #7c7c7c; font-family: Rubik; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px;}
.cptRoundthum .roundthumInfo .infoItem .list:after {content: ''; position: absolute; left: 0; top: 2px; width: 16px; height: 16px; background: url(/images/icon/ico-16-list.svg) center no-repeat;}
.cptRoundthum .roundthumInfo .infoItem .likes {position: relative; height: 20px; padding-left: 20px; color: #7c7c7c; font-family: Rubik; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px;}
.cptRoundthum .roundthumInfo .infoItem .likes:after {content: ''; position: absolute; left: 0; top: 2px; width: 16px; height: 16px; background: url(/images/icon/ico-16-like.svg) center no-repeat;}
.cptRoundthum .roundthumInfo .infoItem .plays {position: relative; height: 20px; padding-left: 20px; color: #7c7c7c; font-family: Rubik; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px;}
.cptRoundthum .roundthumInfo .infoItem .plays:after {content: ''; position: absolute; left: 0; top: 2px; width: 16px; height: 16px; background: url(/images/icon/ico-16-play.svg) center no-repeat;}

.cptRoundthum .btnMore {position: absolute; right: 0; top: 0; width: 40px; height: 40px; background: url(/images/icon/btn-l-add.svg) center no-repeat; text-indent: -9999em; cursor: pointer;}
.cptRoundthum .btnMore:focus-visible {outline:3px solid red !important;}

@media screen and (min-width: 100px) and (max-width: 1023px) {

    .cptRoundthum .roundthumImgGroup {margin-bottom: 16px; padding-top: 24px;}
    .cptRoundthum .roundthumImgGroup .imgBox {width: 104px; height: 104px;}

    .cptRoundthum .roundthumContGroup {padding-bottom: 24px;}
    .cptRoundthum .roundthumTitle {margin-bottom: 4px;}
    .cptRoundthum .roundthumTitle h4 {height: 24px; font-size: 14px; line-height: 1.71; letter-spacing: -0.29px;}
    .cptRoundthum .roundthumHost {margin-bottom: 16px;}
    .cptRoundthum .roundthumHost .name {height: 24px; font-size: 14px; line-height: 1.71; letter-spacing: -0.29px;}

    .cptRoundthum .btnMore {display: none;}
}
